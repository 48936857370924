import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["textChoiceInput"];

  async addChoice() {
    const choice = this.textChoiceInputTarget.value.trim();
    if (!!choice === false) {
      return;
    }

    const previewButton = document.querySelector("#task_preview");
    if (previewButton) {
      previewButton.classList.add("btn-primary--disabled");
    }

    const choiceName = encodeURIComponent(choice);
    const questionType = this.element.dataset.questionType;
    const choiceIndex = this.element.dataset.choiceIndex;
    const appendChoiceUrl = this.element.dataset.appendChoiceUrl;
    const url = `${appendChoiceUrl}?choice_name=${choiceName}&question_index=${this.element.dataset.questionIndex}&choice_index=${choiceIndex}&question_type=${questionType}`;
    fetch(url, {
      headers: {
        Accept: "text/vnd.turbo-stream.html",
      },
    })
      .then((r) => r.text())
      .then((html) => {
        Turbo.renderStreamMessage(html);

        const choiceDefaultNoneFields = document.getElementById(`choice_default_none_fields_${this.element.dataset.questionIndex}`);
        if (choiceDefaultNoneFields && choiceDefaultNoneFields.classList.contains("hidden")) {
          choiceDefaultNoneFields.classList.remove("hidden");
        }
      });
  }
}
