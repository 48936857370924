import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["choiceField", "deleteField", "isAnswer"];

  deleteChoice() {
    const previewButton = document.querySelector("#task_preview");
    if (previewButton) {
      previewButton.classList.add("btn-primary--disabled");
    }

    this.choiceFieldTarget.classList.add("hidden");
    this.deleteFieldTarget.value = true;
    this.isAnswerTarget.value = null;
  }
}
