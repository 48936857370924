import { Controller } from "@hotwired/stimulus";
import TurboRequest from "../helpers/turbo-request";
import { getUrlWithParams } from "../helpers/request-formatter";
import consumer from "../channels/consumer";

export default class extends Controller {
  static targets = [
    "historicalField",
    "rawField",
    "skuField",
    "volumeTargetUserCount",
    "downloadButton",
  ];

  VOLUME_TARGET_USER_LOADING_MSG =
    "Processing... Once complete, you'll be able to download the user list and activate the campaign.";

  connect() {
    this.targetType = this.element.dataset.targetType;
    this.calculateMethod = this.element.dataset.calculateMethod;
    this.replaceProductListSelectionUrl =
      this.element.dataset.replaceProductListSelectionUrl;
    this.replaceTargetTypeUrl = this.element.dataset.replaceTargetTypeUrl;
    this._setProductFieldVisibility(!this._isDownlineOrBlankTargetType());
    this._setHistoricalFieldVisibility(this.calculateMethod);

    this.channel = consumer.subscriptions.create(
      { channel: "JobStatusChannel" },
      {
        connected: () => {
          console.debug("connected to JobStatusChannel");
        },
        disconnected: () => {
          console.debug("disconnected from JobStatusChannel");
        },
        received: (data) => {
          if (data.job_type !== "calculate_volume_target_users_job") {
            return;
          }

          switch (data.status) {
            case "done":
              this._showCountMessage(data.params.volume_target_user_count);
              this._setDownloadButtonVisibility(true);
              break;
            case "running":
              this._showCountMessage(this.VOLUME_TARGET_USER_LOADING_MSG);
              this._setDownloadButtonVisibility(false);
              break;
            case "failed":
              this._showCountMessage(
                "Failed, please try again or check your configuration"
              );
              this._setDownloadButtonVisibility(false);
              break;
            case "cancelled":
              this._showCountMessage("Job was cancelled");
              this._setDownloadButtonVisibility(false);
              break;
          }
        },
      }
    );
  }

  selectTargetType(event) {
    const targetType = event.target.dataset.targetType;
    if (targetType === "ws_downline") {
      this._setProductFieldVisibility(false);
      return;
    }

    this._setProductFieldVisibility(true);
    const url = getUrlWithParams(this.replaceProductListSelectionUrl, [
      ["volume_target_type", targetType],
    ]);
    TurboRequest.get(url);
  }

  selectAudienceType(event) {
    const audienceType = event.target.dataset.audienceType;
    const url = getUrlWithParams(this.replaceTargetTypeUrl, [
      ["audience_type", audienceType],
    ]);
    this._setProductFieldVisibility(false);

    TurboRequest.get(url);
  }

  selectCalculateMethod(event) {
    const calculateMethod = event.target.dataset.calculateMethod;
    this._setHistoricalFieldVisibility(calculateMethod);
  }

  _setProductFieldVisibility(enabled) {
    if (enabled) {
      if (this.hasSkuFieldTarget) {
        this.skuFieldTarget.classList.remove("hidden");
      }
    } else {
      if (this.hasSkuFieldTarget) {
        this.skuFieldTarget.classList.add("hidden");

        const select = this.skuFieldTarget.querySelector("select");
        if (select) {
          select.innerHTML = "";
          select.selectedIndex = 0;
        }

        const preview = this.skuFieldTarget.querySelector(
          "[data-multiselect-target='preview']"
        );
        if (preview) {
          preview.innerHTML = "";
        }
      }
    }
  }

  _setHistoricalFieldVisibility(calculateMethod) {
    if (calculateMethod === "historical" && calculateMethod !== "") {
      this.hasHistoricalFieldTarget &&
        this.historicalFieldTarget.classList.remove("hidden");

      this.hasRawFieldTarget && this.rawFieldTarget.classList.add("hidden");
    } else {
      this.hasHistoricalFieldTarget &&
        this.historicalFieldTarget.classList.add("hidden");

      this.hasRawFieldTarget && this.rawFieldTarget.classList.remove("hidden");
    }
  }

  _isDownlineOrBlankTargetType() {
    return this.targetType === "ws_downline" || this.targetType === "";
  }

  _showCountMessage(message) {
    if (this.hasVolumeTargetUserCountTarget) {
      this.volumeTargetUserCountTarget.textContent = message;
    }
  }

  _setDownloadButtonVisibility(visible) {
    if (this.hasDownloadButtonTarget) {
      this.downloadButtonTarget.classList.toggle("hidden", !visible);
    }
  }
}
