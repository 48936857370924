import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["loadMore"]

  connect() {
    this.boundScrollLoadMore = this.scrollLoadMore.bind(this);
    window.addEventListener("scroll", this.boundScrollLoadMore, { passive: true });
  }

  disconnect() {
    window.removeEventListener("scroll", this.boundScrollLoadMore);
  }

  scrollLoadMore() {
    const scrollPosition = window.scrollY + window.innerHeight;
    const totalHeight = document.documentElement.scrollHeight;
    if (this.hasLoadMoreTarget && scrollPosition >= totalHeight) {
        this.loadMoreTarget.click();
    }
  }
}
